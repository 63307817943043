// Sidebar Base

@use "sass:math";

.app-sidebar {
  width: $app-sidebar-width;
  display: flex;
  z-index: 11;
  overflow: hidden;
  min-width: $app-sidebar-width;
  position: relative;
  flex: 0 0 $app-sidebar-width;
  margin-top: -$app-header-height;
  padding-top: $app-header-height;
  transition: all .2s;

  .app-sidebar__inner {
    padding: 1px ($layout-spacer-x) $layout-spacer-x;
  
  }

  .scrollbar-container {
    z-index: 15;
    width: 100%;
  }

  .app-sidebar-bg {
    position: absolute;
    top: 85%;
    left:15%;
    height: 65%;
    width: 65%;
    opacity: 0.01;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }

  .app-sidebar-logoU {
    //position: relative;
    //left: 0;
    //top: 0;
    //object-fit: cover;
    //height: 65%;
    //width: 65%;
    opacity: 0.05;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }

  .app-header__logo {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    z-index: 11;
  }

  &.sidebar-shadow {
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  }
}

.app-sidebar__heading {
  text-transform: uppercase;
  font-size: $font-size-xs;
  margin: math.div($layout-spacer-x, 2) 0;
  font-weight: bold;
  color: $primary;
  white-space: nowrap;
  position: relative;
}

.sidebar-mobile-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  opacity: .6;
  left: 0;
  top: 0;
  z-index: 12;
}

/*.back-unops{
    //background: url('../../../assets/utils/images/UNOPS_25_RGB_web_EN.png');
    max-width: 100%;
    height:100%; 
    //border:1px solid orange;
    background-image:url('../../../assets/utils/images/sidebar/UNOPS_25_RGB_web_EN.png');
    //background-position: bottom right;
    background-position: 50% 100%;
    background-repeat: no-repeat;  
}*/

.back-unops{
  //background: url('../../../assets/utils/images/UNOPS_25_RGB_web_EN.png');
  max-width: 270px;
  height:150px; 
  //border:1px solid orange;
  background-image:url('../../../assets/utils/images/sidebar/tijax-paraguay-vetical.png');
  background-position: bottom;
  background-position: 50% 100%;
  background-repeat: no-repeat;  
}

.back-google{
  width: 100%;
  align-items: center;
  margin:auto;
}

// Sidebar Components

@import "modifiers/navmenu-vertical";

// Sidebar Modifiers

@import "themes/sidebar-light";
@import "themes/sidebar-dark";
//@import "themes/sidebar-inverted";


@import "modifiers/fixed-sidebar";
@import "modifiers/closed-sidebar";
